import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColor } = coloricon;

export const MenuAside = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonHeader = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;

  svg {
    color: ${fontColor};
    transition: all 0.3s ease-in-out;
    font-size: 25px;

    :hover {
      color: ${secondaryColor};
    }
  }
`;
