import { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import coloricon from './coloricon';

const { background, fontColor } = coloricon;

export default createGlobalStyle`

 * {
     margin: 0;
     padding: 0;
     outline: 0;
     box-sizing: border-box;
     text-decoration: none;
     list-style: none;
 }

 *:focus {
     outline: 0; /* serve principalmente pra input onde chrome deixa azulzinho*/
 }

/* para ocupar sempre a largura máxima da tela */
 html , body , #root {
     height: 100%;
 }

 body {
     -webkit-font-smoothing: antialiased;
     color: ${fontColor};
     background-color: ${background} ;
 }

/* por padrao o input e o button nao herdam a fonte do body, ai eu forço*/
 body , input , button {
     font-size: 16px;
     font-family:  'Roboto Slab', serif;
 }

 h1,h2,h3,h4,h5,h6, strong {
   font-weight: 500;
   color: ${fontColor};
 }

 h1{
   font-size: 1.3em;
 }

 h2{
   font-size: 1.2em;
 }

 h3{
   font-size: 1.15em;
 }

 p,span{
   font-size: 1em;
   color: ${fontColor};
 }

 a , ul li{
   text-decoration: none;
   list-style: none;
 }

 button {
     cursor: pointer;
 }
`;
