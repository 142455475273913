import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function Button({ type, ...rest }) {
  return <Container {...rest} type={type} />;
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
};
