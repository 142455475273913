import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, fontColor, secondaryColor } = coloricon;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100px;
  background-color: ${backgroundHeader};

  @media (max-width: 800px) {
    font-size: 14px;
  }

  @media (max-width: 650px) {
    height: auto;
  }
`;

export const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 20px 0px;
  }

  a {
    color: ${fontColor};
    font-size: 1em;
    transition: all 0.3s ease-in-out;

    @media (max-width: 650px) {
      margin-bottom: 10px;
    }

    :hover {
      color: ${secondaryColor};
    }
  }
`;

export const FooterCopy = styled.h2`
  font-size: 1em;
  text-align: center;

  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 650px) {
    margin-bottom: 20px;
  }

  a {
    color: ${fontColor};
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-left: 5px;

    svg {
      font-size: 1.1em;
    }

    &:hover {
      color: ${secondaryColor};
    }

    & + a {
      margin-left: 10px;
    }
  }
`;
