import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Container, ContainerError } from './styles';
import coloricon from '../../styles/coloricon';

export default function Input({
  icon: Icon,
  name,
  placeholder,
  isTextAreaInput,
  initialData,
  ...rest
}) {
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const inputRef = useRef(null);
  const { secondaryColor, fontColor, placeholderInputColor, backgroundInput } =
    coloricon;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: () => {
        setIsFocused(false);
        setIsFilled(false);
        inputRef.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const useStyles = makeStyles({
    root: {
      backgroundColor: backgroundInput,
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: placeholderInputColor,
      },
      '& .MuiOutlinedInput-input': {
        color: fontColor,
        height: isTextAreaInput ? 'auto' : 11, // aqui é o tamanho do input mas preciso ajustar no textarea
        fontFamily: 'Roboto Slab , serif',
        fontSize: 16,
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: placeholderInputColor,
          opacity: 1,
          fontFamily: 'Roboto Slab , serif',
          fontSize: 16,
        },
        /* tirar a seta do tipo number */
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
        },
        /* mudar a cor do icone do time */
        '&::-webkit-calendar-picker-indicator': {
          filter: `invert(37%) sepia(9%) saturate(170%) hue-rotate(349deg)
            brightness(100%) contrast(91%)`,
        },
      },
      '& .MuiInputLabel-outlined': {
        color: placeholderInputColor,
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: secondaryColor,
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        color: fontColor,
      },

      '& .MuiInputLabel-outlined.Mui-focused': {
        color: secondaryColor,
      },
    },
  });

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current.value);
  }, []);

  const classes = useStyles();

  /* as propriedades min, max, size e step eu uso via inputProps
   inputProps={{ step: '0.01' }}
  */

  return (
    <Container isFocused={isFocused}>
      <TextField
        {...rest}
        inputRef={inputRef} // IMPORTANTE: aqui usamos inputref ou inves de ref
        defaultValue={initialData || defaultValue}
        className={classes.root}
        variant="outlined"
        onFocus={handleFocus}
        placeholder={placeholder}
        size="medium"
        label={placeholder}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {Icon && (
                <Icon
                  size={22}
                  color={
                    isFocused === true || isFilled === true
                      ? `${secondaryColor}`
                      : `${placeholderInputColor}`
                  }
                />
              )}
            </InputAdornment>
          ),
        }}
      />

      <ContainerError>
        {error && <FiAlertCircle size={20} />}
        {error && <span>{error}</span>}
      </ContainerError>
    </Container>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isTextAreaInput: PropTypes.bool,
  initialData: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.string,
    PropTypes.number,
  ]),
};

Input.defaultProps = {
  icon: null,
  isTextAreaInput: null,
  initialData: null,
};
