import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import pt from 'date-fns/locale/pt'; // pra ajustar calendário brasil
// import { subDays, addDays } from 'date-fns';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from './styles';
import coloricon from '../../styles/coloricon';

export default function DatePicker({ name, ...rest }) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || null);
  const { secondaryColor } = coloricon;

  registerLocale('pt', pt);
  setDefaultLocale('pt');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Calendar>
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        locale={pt}
        onChange={setDate}
        timeIntervals={30}
        dateFormat="dd/MM/yyyy"
        {...rest}
      />

      {error && <span style={{ color: secondaryColor }}>{error}</span>}
    </Calendar>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
};
