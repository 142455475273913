import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from './styles';
import coloricon from '../../styles/coloricon';

export default function PageNotFound() {
  const { IconAlert, IconHome, IconLogin } = coloricon;
  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <IconAlert />

        <h1>[ ATENÇÃO! ] - Pagina não encontrada!</h1>
        <h2>A página que você tentou acessar não existe ou foi removida!</h2>

        <button type="button" onClick={() => navigate({ pathname: '/' })}>
          <IconHome />
          <h1>Página Inicial</h1>
        </button>

        <button type="button" onClick={() => navigate(-1)}>
          <IconLogin />
          <h1>Retornar onde parou</h1>
        </button>
      </Content>
    </Container>
  );
}
