import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  margin: 5px 0px;

  span {
    display: flex;
    align-items: center;
    color: ${secondaryColor};
    font-size: 14px;
  }

  svg {
    color: ${secondaryColor};
    margin-right: 5px;
  }
`;
