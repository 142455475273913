import React from 'react';
import PropTypes from 'prop-types';
import coloricon from '../../styles/coloricon';

const { fontColor, backgroundInput } = coloricon;
// inicial era const
const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter, width } = column;
  // tirar width daqui e inserir no item do style

  return (
    <input
      style={{
        margin: 3,
        width,
        backgroundColor: fontColor,
        color: backgroundInput,
        padding: 3,
        border: 0,
        outline: 0,
        borderRadius: 3,
        height: 30,
      }}
      value={filterValue || ''}
      onChange={(event) => setFilter(event.target.value)}
    />
  );
};

export default ColumnFilter;

ColumnFilter.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

ColumnFilter.defaultProps = {
  column: '',
};
