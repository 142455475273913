import React from 'react';
import { Link } from 'react-router-dom';
import coloricon from '../../styles/coloricon';
import { Container, NamePage } from './styles';

function BreadCrumbsMUI({ nameOfCurrentPage, arrayOfTitleAndPath }) {
  const { IconChevron } = coloricon;

  return (
    <Container>
      {arrayOfTitleAndPath.map((item, index) => (
        <div key={item.path}>
          <Link to={item.path}>{item.title}</Link>
          <IconChevron />
          {Number(index) === Number(arrayOfTitleAndPath.length) - 1 && (
            <NamePage>{nameOfCurrentPage}</NamePage>
          )}
        </div>
      ))}
    </Container>
  );
}

export default BreadCrumbsMUI;
