import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const {
  backgroundHeader,
  backgroundHeaderHover,
  fontColor,
  secondaryColor,
  backgroundInput,
} = coloricon;

/* estilos para sobreescrever o css padrao do calendario */

export const Calendar = styled.div`
  margin: 10px 0px;

  /* body*/
  .react-datepicker {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    background-color: ${backgroundHeader};
    border: 1px solid ${backgroundHeaderHover};
  }

  /* input */
  input {
    font-size: 17px;
    text-align: center;
    background-color: ${backgroundInput};
    color: ${fontColor};

    padding: 3px 5px;
    border: 2px solid ${backgroundHeaderHover};
  }

  /* header */
  .react-datepicker__header {
    background-color: ${backgroundHeaderHover};
    border-bottom: 1px solid ${backgroundHeaderHover};
  }

  /* textos do header mes e ano */
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${fontColor};
    text-transform: uppercase;
  }

  /* dias ativos*/
  .react-datepicker__day {
    color: ${fontColor};
  }

  /* textos do header dias da semana */
  .react-datepicker__day-name {
    color: ${fontColor};
    opacity: 0.7;
  }

  /* dias que já passaram*/
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: ${fontColor};
    opacity: 0.3;
  }

  /* dia ativo selecionado*/
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__year-text--selected {
    background-color: ${secondaryColor};
    color: ${backgroundHeader};
  }

  /* hover para o dia ativo*/
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: ${backgroundHeaderHover};
  }

  /* hover para o dia ativo que já passou*/
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  /* hover para o dia ativo selecionado*/
  .react-datepicker__day--selected:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__year-text--selected:hover {
    background-color: ${secondaryColor};
    color: ${backgroundHeader};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${secondaryColor};
    color: ${backgroundHeader};
  }

  /* horario ao lado , o time*/
  .react-datepicker__time-container .react-datepicker__time {
    background: ${backgroundHeader};
    color: ${fontColor};
  }

  /* hover para o item list*/
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: ${backgroundHeaderHover};
  }

  /* para o dia selecionado */
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${secondaryColor};
    color: ${backgroundHeader};
  }
`;
