import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiAlertCircle } from 'react-icons/fi';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import coloricon from '../../styles/coloricon';
import { ContainerError } from './styles';

export default function Select({ name, placeholder, icon: Icon, ...rest }) {
  const inputRef = useRef({ value: '' });
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const {
    backgroundHeaderHover,
    backgroundHeader,
    placeholderInputColor,
    backgroundInput,
    fontColor,
    secondaryColor,
  } = coloricon;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        const data = ref.state.selectValue;

        if (data.length === 0) {
          return '';
        }

        return data[0].value;
      },
    });
  }, [fieldName, registerField]);

  const dot = (color = secondaryColor) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 13,
      marginLeft: 10,
      height: 12,
      width: 12,
    },
  });

  const colourStyles = {
    /* control é o botao principal do input */
    control: (styles) => ({
      ...styles,
      backgroundColor: backgroundInput, // background button
      borderRadius: 4,
      marginBottom: 5,
      height: 50,
    }),
    /* menu é a lista quando aparece */
    menu: (provided) => ({
      ...provided,
      width: '100%',
      color: fontColor,
      padding: 20,
      backgroundColor: backgroundInput, // background menu
    }),
    singleValue: (styles) => ({ ...styles, ...dot() }), // icone do item selecionado
  };

  const placeholderComponent = (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      {Icon && <Icon size={20} />}
      <span style={{ marginLeft: 5, fontSize: 17 }}>{placeholder}</span>
    </div>
  );

  return (
    <>
      <ReactSelect
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: backgroundHeaderHover,
            primary: backgroundHeader,
            neutral20: placeholderInputColor, // borda do input
            neutral30: placeholderInputColor, // mouse hover hover
            neutral50: placeholderInputColor, // placeholder
            neutral80: fontColor, // item selecionado
          },
        })}
        defaultValue={defaultValue}
        ref={inputRef}
        classNamePrefix="react-select"
        {...rest}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? 'Sem opções' : 'Sem registros para exibir.'
        }
        placeholder={placeholderComponent}
      />
      <ContainerError>
        {error && <FiAlertCircle size={20} />}
        {error && <span>{error}</span>}
      </ContainerError>
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};
