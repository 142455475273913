import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, fontColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  > svg {
    font-size: 90px;
    margin: 20px 0px;
  }

  > h1 {
    color: ${secondaryColor};
    font-weight: bold;
    text-align: center;
    margin: 20px 0px;
  }

  > h2 {
    text-align: center;
    margin: 20px 0px;
  }

  > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 10px 0px;

    &:hover {
      svg,
      h1 {
        color: ${secondaryColor};
      }
    }

    > svg {
      font-size: 20px;
      margin-right: 7px;
      color: ${fontColor};
      transition: all 0.3s ease-in-out;
    }

    > h1 {
      color: ${fontColor};
      transition: all 0.3s ease-in-out;
    }
  }
`;
