import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColor, secondaryColorHover } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > a {
      color: ${fontColor};
      font-size: 1.2em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: ${secondaryColorHover};
      }
    }

    svg {
      color: ${secondaryColor};
      font-size: 18px;
      margin: 0px 10px;
    }
  }
`;

export const NamePage = styled.h1`
  font-size: 1em;
  color: ${fontColor};
`;
